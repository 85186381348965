import React from "react";

const NotFoundPage = () => (
  <div>
    <h1>Not found</h1>
    <p>You just hit a page that doesn&#39;t exist...</p>
  </div>
);

export default NotFoundPage;
